import React from 'react'
import classnames from 'classnames'
import { container, heading } from './Info.module.scss'
import { Title } from '../../ui/Title'

const infoData = [
	{ title: '社名', text: 'Lisa Technologies 株式会社' },
	{ title: '設立', text: '2017年8月29日' },
	{ title: '代表取締役', text: '西村 龍紀' },
	{ title: '資本金', text: '1億2,350万円（資本準備金を含む）' },
	{ title: '所在地', text: '〒103-0004\n東京都中央区東日本橋2丁目24−9 LIT 4F' },
	{ title: '電話番号', text: '050-3138-1514' },
	{ title: '従業員数', text: '19 名（2021年9月1日現在）' },
	{ title: '事業内容', text: 'フードデリバリー最適化クラウド「フードデリバリーマネージャー」とフードデリバリーブランドのフランチャイズ比較「huriuri」の企画・開発・運営' },
]

export const Info = () => {
	return (
		<div className={container} id="info-position">
			<Title title="会社概要" titleSub="OUTLINE" horizontal={false} className="flex-col items-center" />
			<div className="mt-12 border-b">
				{infoData.map((info) => {
					return (
						<div key={info.title} className="flex items-center py-5 lg:py-6 border-t">
							<h3 className={classnames(heading, 'text-sm font-medium')}>{info.title}</h3>
							<p className="ml-1 lg:ml-0 w-auto text-sm">
								{info.text.split('\n').map((txt) => {
									return (
										<span key={txt} className="block">
											{txt}
										</span>
									)
								})}
							</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}
